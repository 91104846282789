<template>
	<section class="relative flex min-h-80 w-full items-start lg:ml-0">
		<div
			ref="carouselContainer"
			class="no-scrollbar flex flex-row gap-2 overflow-scroll"
			@scroll="checkArrows"
		>
			<div v-for="item in truncatedItems" :key="item.id" class="w-80 space-y-2">
				<!-- Image and Price -->
				<NuxtLink
					:to="`/products/${item.productMetadata?.slug}`"
					no-prefetch
					class="relative flex h-56 w-80 items-center justify-center rounded-lg bg-grey-very-light"
				>
					<!-- Price -->
					<div
						v-if="item.price"
						class="absolute right-2 top-2 flex size-12 items-center justify-center rounded-full bg-primary font-medium text-text"
					>
						${{ item.price }}
					</div>
					<!-- Image -->
					<img
						v-if="item.productMetadata?.productImages?.length"
						:src="
							item.productMetadata.productImages[0].urlThumbnail ||
							item.productMetadata.productImages[0].url
						"
						:alt="
							item.productMetadata.productImages[0].altText ||
							`Weedys ${item.name} product image`
						"
						:longdesc="item.productMetadata.productImages[0].description"
						class="size-52 mix-blend-darken"
						loading="lazy"
					/>
					<ImagePlaceholder v-else class="size-52 mix-blend-darken" />
				</NuxtLink>
				<!-- Info -->
				<div class="grid grid-cols-5 grid-rows-2 items-start justify-between px-1">
					<NuxtLink
						:to="`/products/${item.productMetadata?.slug}`"
						no-prefetch
						class="col-span-3 text-lg font-semibold text-text"
					>
						{{ item.name }}
					</NuxtLink>
					<NuxtLink
						:to="`/products/${item.productMetadata?.slug}`"
						no-prefetch
						class="col-span-2 row-span-2 flex justify-center rounded-full border-2 border-text px-4 py-1 font-medium text-text"
						:aria-label="`Shop now - ${item.name}`"
					>
						Shop now
					</NuxtLink>
					<p
						v-if="item.strains?.lineage || item.productMetadata?.dealTag"
						class="col-span-3 truncate text-xs font-light md:text-sm"
					>
						{{ item.strains?.lineage || item.productMetadata?.dealTag }}
					</p>
				</div>
			</div>
		</div>

		<CarouselNavigationArrow
			:is-visible="showLeftArrow"
			class="-mt-2"
			@scroll="slide('left', items.length)"
		/>
		<CarouselNavigationArrow
			direction="right"
			:is-visible="showRightArrow"
			class="-mt-2"
			@scroll="slide('right', items.length)"
		/>
	</section>
</template>

<script setup lang="ts">
import CarouselNavigationArrow from '../ui/CarouselNavigationArrow.vue';
import useCarouselNavigation from '../../composables/carouselNavigation';
import ImagePlaceholder from '../icons/ImagePlaceholder.vue';
import type { Product } from '~/types/app.types';

const props = defineProps<{ items: Product[] }>();

const carouselContainer = ref<HTMLDivElement | null>(null);
const truncatedItems = computed(() => props.items.slice(0, 8));

const { showLeftArrow, showRightArrow, slide, checkArrows } =
	useCarouselNavigation(carouselContainer);
</script>
